import { createFeatureSelector, createSelector } from '@ngrx/store';
import { projectsFeatureKey, ProjectsState, selectAll } from './projects.reducer';
import { ProjectStatusKey } from '../../pages/webapp/projects/projects.interface';

export const getProjectsState = createFeatureSelector<ProjectsState>(projectsFeatureKey);
export const getAllProjects = createSelector(getProjectsState, selectAll);

export const getNonDeletedProjects = createSelector(getAllProjects, (projects) => {
  return projects.filter((project) => {
    const status = project?.project_status?.key ?? project.status;
    return status !== 'deleted' && status !== 'archived';
  });
});

export const getSimpleProjects = createSelector(getProjectsState, (state) => state.simpleProjects);
export const areSimpleProjectsLoading = createSelector(
  getProjectsState,
  (state) => state.areSimpleProjectsLoading,
);

export const isEditProjectLoading = createSelector(
  getProjectsState,
  (state) => state.isEditProjectLoading,
);

export const getProjectStatuses = createSelector(getProjectsState, (state) => state.statuses);

export const getProjectStatusesExcept = (filterStatuses: ProjectStatusKey[]) =>
  createSelector(getProjectStatuses, (allStatuses) =>
    allStatuses.filter((s) => !filterStatuses.includes(s.key)),
  );

export const getProjectStatusesByKey = (keys: ProjectStatusKey[]) =>
  createSelector(getProjectStatuses, (allStatuses) =>
    allStatuses.filter((s) => keys.includes(s.key)),
  );

export const selectMentionProjects = createSelector(getSimpleProjects, (projects) => {
  return projects.map((project) => ({
    id: project.id,
    name: project.title,
  }));
});
